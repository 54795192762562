import axios from 'axios';

let baseURL = process.env.REACT_APP_URL;
let token = process.env.REACT_APP_JWT_TOKEN;

export const postData = async (data) => {
  try {
    const response = await axios.post(`${baseURL}`, data, {
      headers: {
        'Authorization': `${token}`
      }
    });

    if (data.method === "loginUser" || data.method === "logoutUser" || data.method === "bookmarkVerse" || data.method === "unBookmarkVerse" || data.method  === "deleteUserAccount") { 
      // console.log(response.data);
      return response.data;
    }

    if (response.data === "success" || response.data.status === "success") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error posting data:', error);
    
    throw error;
  }
};

export const getData = async (method) => {
  try {
    const response = await axios.get(`${baseURL}?method=${method}`, {
      headers: {
        'Authorization': `${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error getting data:', error);
    throw error;
  }
};
