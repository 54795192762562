import './App.css';
import Routers from './Routers/Routers';

// import Logo from "./assets/images/backgroundImage.png";

import { Grid } from '@mui/material';

// const imageStyle = {
//   backgroundImage: `url(${Logo})`,
//   height: "100vh",
//   opacity: "1",
//   backgroundRepeat: "no-repeat",
//   backgroundAttachment: "fixed"
// }sx={imageStyle}

function App() {
  return (
    <Grid className="App" >
      <Grid sx={{ backgroundColor: "rgba(255,255,255,0.3)"}}>
        <Routers />
      </Grid>
    </Grid>
  );
}

export default App;
