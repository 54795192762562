import { Grid } from "@mui/material";
import { getData } from "../../services/api";
import { useState, useEffect } from "react";
import BasicModal from "../Modal";

import { Typography, CardActions, Box, IconButton, Divider } from "@mui/material";

import ShareIconButton from "../ShareIcon";
import LikeButton from "../LikeButton";

import styles from './vom.module.css'

import CardComponent from "../CardComponent";

const VersesOfTheMonth = () => {
    const [responseData, setResponseData] = useState(null);
    const handleGet = async () => {
        try {
            
            const response = await getData("fetchVerseOfTheMonth");
            setResponseData(response);
        } catch (error) {
            console.error('Error in handleGet:', error);
        }
      };

    useEffect(() => {
        handleGet();
    }, []);

    const cardContent = () => (
        <>
            <Box className={styles.heroText}>
                <Typography variant="h4">Verses Of The Month</Typography>
            </Box>
            {(responseData && responseData.length > 0) ? (
                responseData.map((item, index) => (
                    <Box key={index}>
                        <Box className={styles.cardContent}>
                            <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                {item.book} {item.chapter}: {item.verse} ({item.accronymn})
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} variant="h5" color="text.primary" className={styles.mainText}>
                                {item.verse_text}
                            </Typography>
                        </Box>     
                        <CardActions className={styles.cardActions}>
                            <Box sx={{ textAlign: "left", width: "50%" }}>
                                <BasicModal buttonText="Full Chapter" chapterID={item.chapter} bookID={item.bookID} verseID={item.verse} />
                            </Box>
                            <Box sx={{ textAlign: "right", width: "50%" }}>
                                <LikeButton likes={item.likes} id={item.id} disabled={true} />
                                <IconButton aria-label="share" size="small" sx={{ color: "blue !important" }}>
                                    <ShareIconButton
                                        verseID={item.id}
                                        verseBook={item.book}
                                        verseChapter={item.chapter}
                                        verseNumber={item.verse}
                                        verseAccronymn={item.accronymn}
                                        url=""
                                        text="See the verses of the month."
                                    />
                                </IconButton>
                            </Box>
                        </CardActions>
                        {index !== responseData.length - 1 && (
                            <Divider variant="middle" className={styles.divider} />
                        )}
                    </Box>
                ))
            ) : (
                <Box>
                    <Box className={styles.cardContent}>
                        <Typography sx={{ mb: 1.5 }} variant="body1" color="text.primary" className={styles.mainText}>
                            No verses available for this month. Go and rate your favorite verses to see them appear here.
                        </Typography>
                    </Box>
                </Box>
            )} 

        </>
    );

    return (
        <Grid>
            <CardComponent
                cardContent={cardContent}
                cardActions={() => null} 
            /> 
        </Grid>
    );
}

export default VersesOfTheMonth;